import { gsap } from '@/common/motions';

const mixinMainMostion = {
  mounted() {
    const changeTextSplit = (el, brNumber) => {
      let html = '';
      const text = el.innerText.split('');
      for (let i = 0; i < text.length; i += 1) {
        html += `<span>${text[i]}</span>`;
        if (brNumber && brNumber === i) {
          html += '<br>';
        }
      }
      el.innerHTML = html;
    };
    // Split Text - DREAMPLUS
    changeTextSplit(document.querySelector('.logo_text'), 4);

    // Section 1 - DREAMPLUS
    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: '#section1',
      },
    });
    tl1.to('.logo_text', { autoAlpha: 1, ease: 'Power4.easeOut', duration: 1 })
      .staggerFromTo('.logo_text > span', 1.5, { autoAlpha: 0, x: -20 }, {
        autoAlpha: 1, x: 0, stagger: 0.15, ease: 'Power4.easeOut',
      })
      .fromTo('.logo-motion', 1.5, { autoAlpha: 0, x: 20 }, { autoAlpha: 1, x: 0, ease: 'Power4.easeOut' }, '-=1.5')
      .call(() => {
        if (document.querySelector('#logo-motion-video')) {
          document.querySelector('#logo-motion-video').currentTime = 0;
          document.querySelector('#logo-motion-video').play();
        }
      }, null, '-=1.5')
      .to('.scroll', {
        autoAlpha: 1, y: 10, ease: 'Back.easeOut', duration: 2,
      }, '-=.2');
  },
};

export {
  mixinMainMostion,
};
