<template>
  <div class="tab_list" role="tablist"
    :class="classes">
    <!-- <div class="tab_wrap" role="presentation"
      @click="Hander"
    >
      <template v-for="(v, i) in list">
        <slot :paramClass='tabValue === i ? "on" : ""' :title='v' name="default"
          style="border:1px solid red">기본 목록</slot>
      </template>
    </div> -->
    <div class="tab_wrap" role="presentation" ref="track">
      <a href="javascript:void(0);" class="tab" role="tab" ref="tabs" @click="Hander(index)"
          v-for="(tab, index) in list" :key="index"
          :aria-selected="tabValue === index ? true : false"
          :class='tabValue === index ? "on" : ""'
      >{{tab}}</a>
    </div>
  </div>
</template>

<script>
/**
 * 기본 BaseTab 컴포넌트
 */
export default {
  name: 'MobileBaseTab',
  /**
   * https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
   */
  model: {
    prop: 'tabValue',
    event: 'input',
  },
  props: {
    tabValue: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
    },
    /**
     * 타입
     * @values line, expend
     */
    tabtype: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      offsetArray: [],
      totalWidth: 0,
    };
  },
  computed: {
    classes() {
      return this.tabtype;
    },
  },
  methods: {
    moveScroll(idx) {
      const $thisLi = this.$refs.tabs[idx];
      const offsetLeft = this.offsetArray[idx];
      let posX = offsetLeft;
      if (idx > 0) {
        posX = offsetLeft - ((this.totalWidth / 2) - ($thisLi.clientWidth / 2));
      } else {
        posX = 0;
      }
      this.$refs.track.scrollLeft = posX;
    },
    Hander(event) {
      // const indexKey = Array.from(event.currentTarget.children).indexOf(event.target);
      const indexKey = event;
      for (let i = 0; i < this.$refs.track.children.length; i += 1) {
        if (indexKey === i) {
          this.$refs.track.children[i].classList.add('on');
        } else {
          this.$refs.track.children[i].classList.remove('on');
        }
      }
      /**
       * 선택 이벤트
       * @event input
       * @property {number} 선택된 Tab index
       */
      this.moveScroll(indexKey);
      this.$emit('input', indexKey);
    },
    blankEvent() {
      return false;
    },
  },
  mounted() {
    this.$refs.tabs.forEach((e, i) => {
      this.offsetArray[i] = e.offsetLeft;
    });
    this.totalWidth = this.$refs.track.clientWidth;
    this.moveScroll(this.tabValue);
  },
};
</script>

<style scoped>
.tab-wrap .tab.type2 {width:70%; margin:0 auto;}
.tab-wrap .tab.type2 > li {padding-bottom:0; font-size: 30px;}
.tab-wrap .tab.type2 > li:after {content:none}
</style>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```vue
<template>
  <BaseTab
    v-model="tabValue"
    v-bind:list="['대한', '민국']"
    tabtype="type2"
    >
    <template v-slot="Scope">
      <li class="" :class="Scope.paramClass">{{Scope.title}}</li>
    </template>
  </BaseTab>
</template>

<script>
export default {
  data() {
    return {
      tabValue: 0,
    }
  },
  methods: {
  }
};
</script>
```
</docs>
